import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
import dayjs, { Dayjs } from "dayjs";
const config = require("../../../framework/src/config.js")
let tokenData = "";
let cardId = "";

export interface ContractDetailMain {
  contracts?: ContractDetailContracts
  dispute_raise_details?: {}
}

export interface ContractDetailContracts {
  data: ContractDetailContractsData
}

export interface ContractDetailContractsData {
  id: string
  type: string
  attributes: ContractDetailContractsDataAttributes
}

export interface ContractDetailContractsDataAttributes {
  id: number
  proposal_id: number
  work_opportunity_id: number
  account_id: number
  created_at: string
  updated_at: string
  title: string
  wallet: number
  earnings: number
  notification_count: number
  transactions: number
  work_budget: number
  progress_percentage: number
  duration: string | number
  start_date: string
  created_time_ago: string
  project_timeline: number
  project_timeline_type: string
  profile_details: ContractDetailContractsDataAttributesProfileDetails
  active_milestone_details: ContractDetailContractsDataAttributesActiveMilestoneDetail[]
  sendbird_data: ContractDetailContractsDataAttributesSendbirdData
}

export interface ContractDetailContractsDataAttributesProfileDetails {
  profile_photo: string
  profile_location: string
  profile_user_name: string
  profile_backgroud_color: string
}

export interface ContractDetailContractsDataAttributesSendbirdData {
  next: string
  ts: number
}

export interface ContractDetailContractsDataAttributesActiveMilestoneDetail {
  id: number
  name: string
  description: string
  amount: number
  due_date: string
  milestone_status: string
  activate: boolean
  deliverables: ContractDetailContractsDataAttributesActiveMilestoneDetailDeliverable[]
  milestone_type: string
}

export interface ContractDetailContractsDataAttributesActiveMilestoneDetailDeliverable {
  id: number
  deliverable: string
  deliverable_type: string
}

export interface AddMilstoneResponse {
  data: AddMilstoneResponseData
}

export interface AddMilstoneResponseData {
  id: string
  type: string
  attributes: AddMilstoneResponseDataAttributes
}

export interface AddMilstoneResponseDataAttributes {
  id: number
  contract_id: number
  name: string
  description: string
  amount: number
  due_date: string
  client_approval: boolean
  deliverables: AddMilstoneResponseDataAttributesDeliverables
  edits: any
}

export interface AddMilstoneResponseDataAttributesDeliverables {
  data: AddMilstoneResponseDataAttributesDeliverablesData[]
}

export interface AddMilstoneResponseDataAttributesDeliverablesData {
  id: string
  type: string
  attributes: AddMilstoneResponseDataAttributesDeliverablesDataAttributes
}

export interface AddMilstoneResponseDataAttributesDeliverablesDataAttributes {
  id: number
  deliverable: string
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  menuOpen: boolean;
  showNotification: boolean;
  notification_count: number;
  unread_notification_count: number;
  mainDetails: ContractDetailMain
  isViewAllMilestone: boolean;
  showUpcoming: boolean;
  addMileStone: boolean;
  milestoneName: string;
  milestoneDescription: string;
  milestoneDeliverables: {
    id: number | string;
    deliverable: string;
    deliverableError: boolean;
    deliverable_type: string;
    prevAdd: boolean
  }[];
  milestoneAmount: number | null;
  milestoneDueDate: Dayjs | null;
  isMilestoneNameLimit: boolean
  deleteDataState: any[]
  openCalender: boolean
  isWordLimitDescription: boolean
  feedbackError: boolean;
  raiseDisputeDescriptionError: boolean
  isWordLimitReasonDescritpion: boolean
  isWordLimitSubmitDescritpion: boolean
  editAmountCheck: boolean;
  expanded: boolean[];
  accordingToCondiitonData: any[];
  mileStoneData: any[];
  editPopUp: boolean;
  milestoneId: number;
  milestoneType: string;
  isViewSubmessionPopUp: boolean
  viewAllMilestoneData: any[];
  remaining_milestones: number;
  toastOpen: boolean;
  toastError: string;
  message: string;
  action: string;
  submessionLinksData:any[];
  resourcelinks:any[];
  isViewSubmessionPopUpAll: boolean
  viewSubmessionData:any[]
  afterSubmessiondata:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContractDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiActiveContrcatDetail: string = ''
  editMilestoneId: string = "";
  postAddMilestoneID: string = ""
  allSubmissionId: string = ''
  resourceLinkId: string = ''
  viewSubmissionId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      menuOpen: false,
      showNotification: false,
      notification_count: 41,
      unread_notification_count: 44,
      mainDetails: {},
      isViewAllMilestone: false,
      showUpcoming: false,
      addMileStone: false,
      milestoneName: "",
      milestoneDescription: '',
      milestoneDeliverables: [],
      milestoneAmount: null,
      milestoneDueDate: dayjs(),
      isMilestoneNameLimit: false,
      deleteDataState: [],
      openCalender: false,
      isWordLimitDescription: false,
      feedbackError: false,
      isWordLimitReasonDescritpion: false,
      isWordLimitSubmitDescritpion: false,
      raiseDisputeDescriptionError: false,
      editAmountCheck: false,
      expanded: [],
      accordingToCondiitonData: [],
      mileStoneData: [],
      editPopUp: false,
      milestoneId: 0,
      milestoneType: '',
      isViewSubmessionPopUp: false,
      viewAllMilestoneData: [],
      remaining_milestones: 0,
      action: '',
      message: '',
      toastError: '',
      toastOpen: false,
      submessionLinksData: [],
      resourcelinks: [],
      isViewSubmessionPopUpAll: false,
      viewSubmessionData: [],
      afterSubmessiondata: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiActiveContrcatDetail:
          this.handleActiveContrcatDetailData(responseJson)
          break;

        case this.editMilestoneId:
          this.handleEditMilestoneResponse(responseJson);
          break;

        case this.postAddMilestoneID:
          this.handleAddMilestoneResponse(responseJson);
          break;

        case this.allSubmissionId:
          this.handleSubmissionData(responseJson);
          break;

        case this.resourceLinkId:
          this.handleResourceData(responseJson);
          break;

        case this.viewSubmissionId:
          this.manageViewSubmitData(responseJson);
          break;

        default:
          break;

      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }
    cardId = this.props.navigation.getParam("id")
    this.activeContractAPI()
    this.getAllSubmessionFn()
    this.getResourceLink();
  }

  handleActiveContrcatDetailData = (responseJson: ContractDetailMain) => {
    if (responseJson.contracts) {
      this.setState({ mainDetails: responseJson })


      const milestoneData = responseJson.contracts.data.attributes.active_milestone_details
      const remainingMilestone = milestoneData.length - 2;
      const accordingToConditionData = milestoneData.length > 2 ? milestoneData.slice(0, 2) : milestoneData;

      this.setState({
        accordingToCondiitonData: this.state.isViewAllMilestone ? milestoneData :
          [...accordingToConditionData, { remaining_milestones: remainingMilestone > 0 ? remainingMilestone : 0 }],
        remaining_milestones: remainingMilestone > 0 ? remainingMilestone : 0,
        mileStoneData: milestoneData,
        expanded: new Array(milestoneData.length).fill(false),
      }, () => {
        if (milestoneData.length > 0) {
          this.setState({ milestoneDueDate: dayjs(this.state.mileStoneData[this.state.mileStoneData.length - 1].due_date), })
        }
      })
    }
  }

  handleEditMilestoneResponse = (responseJson: { "message": "success", "errors": "", "error": "" }) => {
    if (!responseJson?.error && !responseJson?.errors) {
      this.setState({ addMileStone: false });
      this.setState(
        {
          action: "success",
          message: "Milestone edited successfully!",
          toastOpen: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              action: "",
              message: "",
              toastOpen: false,
            });
          }, 4000);
        }
      );
      this.resetStateForAddMilestone();
      this.activeContractAPI();
    }
  }

  handleAddMilestoneResponse = (responseJson: { "data": AddMilstoneResponse, "errors": "", "error": "" }) => {
    if (!responseJson?.error && !responseJson?.errors) {
      this.setState(
        {
          toastOpen: true,
          action: "success",
          message: "Milestone created successfully!",
        },
        () => {
          setTimeout(() => {
            this.setState({
              toastOpen: false,
              action: "",
              message: "",
            });
          }, 4000);
        }
      );
      this.resetStateForAddMilestone();
      this.setState({ addMileStone: false });
      this.activeContractAPI();

    }
  }

  handleSubmissionData = (responseJson: any) => {
    const transformedData = responseJson.data.flatMap((item: any) => {
      const submissionTime = item.attributes.submission_time;

      const files = (item.attributes.files || []).map((file: any) => ({
        file_type: file.file_type,
        file_name: file.file_name,
        url: file.url,
        submission_time: submissionTime,
      }));

      const links = (item.attributes.submission_links || []).map((link: any) => ({
        link_url: link,
        submission_time: submissionTime,
      }));

      return [...files, ...links];
    });
    this.setState((prevState) => ({
      submessionLinksData: [...transformedData, ...prevState.submessionLinksData],
    }));
  }

  handleResourceData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({ resourcelinks: responseJson })
    }
  }

  manageViewSubmitData = (responseJson: any) => {
    if(!responseJson.error && !responseJson.errors){
      this.setState({viewSubmessionData:responseJson?.data,afterSubmessiondata:responseJson?.data.length==2 ?true:false})
     }
  }

  handleMenuIcon = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  handleToastClose = () => {
    this.setState({ toastOpen: false })
  }

  handleOpenViewAllMilestone = () => {
    this.setState({
      isViewAllMilestone: true,
      accordingToCondiitonData: this.state.mileStoneData,
      expanded: new Array(this.state.mileStoneData.length).fill(false),
      showUpcoming: false,
    });
  }

  escapeRemainingMilestone = () => {
    const remainingMilestone = this.state.mileStoneData.length - 2;
    const newConditionData = [
      ...this.state.mileStoneData.slice(0, 2),
      { remaining_milestones: Math.max(remainingMilestone, 0) }
    ];
    this.setState(prevState => ({
      accordingToCondiitonData: newConditionData,
      showUpcoming: false,
      expanded: [...prevState.expanded.slice(0, 2), ...new Array(Math.max(remainingMilestone, 0)).fill(false)]
    }));
  }

  handleCloseViewAllMilestone = () => {
    const remainingMilestone = this.state.mileStoneData.length - 2;
    this.setState({
      isViewAllMilestone: false,
      accordingToCondiitonData:
        [...this.state.mileStoneData.slice(0, 2), { remaining_milestones: remainingMilestone > 0 ? remainingMilestone : 0 }],
      expanded: new Array(this.state.mileStoneData.length).fill(false)
    })
  }

  handleAddMileStone = () => {
    this.setState({ addMileStone: true, editPopUp: false })
  }

  handleRemainingMilestone = () => {
    this.setState({ accordingToCondiitonData: this.state.mileStoneData, showUpcoming: true })
  }

  convertDateFormat = (dateString: string) => {
    if (dateString) {
      let [day, month, year] = dateString.split('-');
      year = year.length === 2 ? `20${year}` : year;
      return `${month}/${day}/${year}`;
    }
  }

  openEditMilestonePopUp = (data: any) => {
    const dataMilestone = data.deliverables
      .filter((item: { id: number | string; deliverable: string }) => item.deliverable)
      .map((item: { id: number | string; deliverable: string }) => ({
        ...item,
        deliverableError: false,
        prevAdd: true,
      }));
    this.setState({
      addMileStone: true,
      editAmountCheck: data.activate,
      editPopUp: true,
      milestoneType: data.milestone_type,
      milestoneId: data.id,
      milestoneName: data.name,
      milestoneDescription: data.description,
      milestoneDeliverables: dataMilestone,
      milestoneAmount: data.amount,
      milestoneDueDate: dayjs(data?.due_date),
      isMilestoneNameLimit: false,
      openCalender: false,
      isWordLimitDescription: false
    })
  }

  handleAccordionToggle = (index: number) => {
    const newExpanded = this.state.expanded.map((item: boolean, idx: number) =>
      idx === index ? !item : item
    );
    this.setState(
      { expanded: newExpanded })
  };

  findIndexOfWait = (data: any) => {
    for (let index = 0; index < data?.length; index++) {
      const item = data[index];

      if (item.milestone_status === "approved") {
        if (index + 1 < data.length) {
          if (!data[index + 1].activate) {
            return index + 1;
          }
        }
      }
    }

    return 0;
  };

  downloadImage = async (item: any) => {
    const response = await fetch(config.baseURL + item.url);
    const blob = await response.blob();
    const link = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    link.href = objectUrl;
    link.download = item.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objectUrl);
  };

  showMileStoneNameError = () => {
    return this.state.isMilestoneNameLimit ? "Reached Limit of 50 char" : ""
  }

  showDescriptionError = () => {
    return this.state.isWordLimitSubmitDescritpion ? "Reached Limit of 200 char" : ""
  }
  showMilestoneDescriptionError = () => {
    return this.state.isWordLimitDescription ? "Reached Limit of 500 char" : ""
  }
  showDeliverableError = (select: boolean) => {
    return select ? "Reached Limit of 150 char" : ""
  }

  handleMileStoneName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    const isExceedingLimit = value.length > 50;

    if (!isExceedingLimit) {
      this.setState({ milestoneName: e.target.value });
    }
    this.setState({ isMilestoneNameLimit: isExceedingLimit });

  }

  handleMileStoneNameDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {


    const value = e.target.value.trim();
    const isExceedingLimit = value.length > 500;

    if (!isExceedingLimit) {
      this.setState({ milestoneDescription: e.target.value });
    }

    this.setState({ isWordLimitDescription: isExceedingLimit });


  }

  handleDeliverableChange = (id: number | string, e: any) => {
    const { value } = e.target;

    const updatedData = this.state.milestoneDeliverables.map(
      (milestone: {
        id: number | string;
        deliverable: string;
        deliverableError: boolean;
        prevAdd: boolean
        deliverable_type: string;
      }, index) => {
        if (milestone.id == id) {
          return {
            ...milestone,
            deliverable: value,
            deliverableError: value.trim().length > 150,
          };
        }
        return milestone;
      }
    );

    this.setState({ milestoneDeliverables: updatedData });
  };

  handleDeleteDeliverable = (id: number | string) => {
    const updatedData = this.state.milestoneDeliverables.filter((item: {
      id: number | string;
      deliverable: string;
      deliverableError: boolean;
      prevAdd: boolean
    }, index) => {
      return item.id !== id;
    });
    let deleteData = [];
    const deletDataFind = this.state.milestoneDeliverables.find((item: {
      id: number | string;
      deliverable: string;
      deliverableError: boolean;
      prevAdd: boolean
    }, index) => {
      return item.id == id && item.prevAdd;
    });
    if (deletDataFind) {
      deleteData.push(deletDataFind.id);
      deleteData.push(deletDataFind.deliverable_type);
    }

    this.setState({ milestoneDeliverables: updatedData, deleteDataState: [...this.state.deleteDataState, deleteData] });
  };

  handleAddDeliverable = () => {
    this.setState({
      milestoneDeliverables:
        [...this.state.milestoneDeliverables,
        {
          id: this.state.milestoneDeliverables.length,
          deliverable: "",
          deliverableError: false,
          prevAdd: false,
          deliverable_type: "BxBlockDashboard::Deliverable"
        }]
    });
  };

  handleMileStoneNameAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);
    if (numericValue <= 9999999) {
      this.setState({ milestoneAmount: numericValue })
    } else {
      this.setState({ milestoneAmount: 0 })

    }

  }

  handlePropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }

  openCalender = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openCalender: true
    });
    this.handlePropagation(event);
  }

  isFormValid = () => {
    const { milestoneName, milestoneDescription, milestoneDueDate, milestoneDeliverables, milestoneAmount } = this.state;
    if (this.state.isWordLimitDescription || this.state.isMilestoneNameLimit ||
      !milestoneName || !milestoneDescription || !milestoneAmount || milestoneAmount <= 0 || !milestoneDueDate ||
      milestoneDeliverables.some((item: {
        deliverable: string;
        deliverableError: boolean
      }) => !item.deliverable || item.deliverableError)
    ) {
      return false;
    }
    return true;
  };

  handleApiForAddEditMilestone = () => {
    if (this.state.editPopUp) {
      this.handleEditMilestone()
    }
    else {
      this.handleAddMilestoneApi()
    }
  }

  closeCalender = () => {
    this.setState({
      openCalender: false
    });
  }

  handleDateChange = (date: Dayjs | null) => {
    this.setState({ milestoneDueDate: date });
  };

  resetStateForAddMilestone = () => {
    this.setState({
      milestoneName: "",
      milestoneDescription: "",
      milestoneDeliverables: [
        {
          id: 0,
          deliverable: "",
          deliverableError: false,
          prevAdd: false,
          deliverable_type: "BxBlockDashboard::Deliverable"
        }],
      milestoneAmount: null,
      milestoneDueDate: dayjs(this.state.mileStoneData[this.state.mileStoneData.length - 1].due_date),
      isMilestoneNameLimit: false,
      deleteDataState: [],
      openCalender: false,
      isWordLimitDescription: false
    })
  }

  handleAddMileStoneClose = () => {
    this.setState({ addMileStone: false })
    this.resetStateForAddMilestone();
  }

  activeContractAPI = () => {
    const headerObj = {
      "token": tokenData,
      'Content-Type': configJSON.ApiContentType,
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiActiveContrcatDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activeContractApiEndPoint + cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEditMilestone = () => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData
    };
    const data = this.state.milestoneDeliverables.map((item: any) => {
      if (item.prevAdd) {
        return {
          id: item.id,
          deliverable: item.deliverable,
          deliverable_type: item.deliverable_type
        }
      }
      else {
        return {
          new_deliverable: item.deliverable,
        }

      }

    })

    let formattedDate = ''
    if (this.state.milestoneDueDate) {
      formattedDate = this.state.milestoneDueDate.format("YYYY-MM-DD");
    }

    const httpBody = {
      "data": {
        "attributes": {
          "contract_id": cardId,
          "milestone_type": "BxBlockDashboard::ContractMilestone",
          "milestone_id": this.state.milestoneId,
          "milestone_edits": {
            "name": this.state.milestoneName,
            "description": this.state.milestoneDescription,
            "amount": this.state.milestoneAmount,
            "due_date": formattedDate,
            "deliverables": data,
            "deleted_deliverables": this.state.deleteDataState
          },
        }
      }
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editMilestoneId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/client_contracts/edit_milestone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddMilestoneApi = () => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData
    };
    const deliverablesData = this.state.milestoneDeliverables.map((item) => {
      return item.deliverable
    })
    const httpBody = {
      "data": {
        "attributes": {
          "contract_id": cardId,
          "milestone_details": {
            "name": this.state.milestoneName,
            "description": this.state.milestoneDescription,
            "deliverables": deliverablesData,
            "amount": this.state.milestoneAmount,
            "due_date": this.state.milestoneDueDate?.format("DD-MM-YYYY")
          }
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAddMilestoneID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_cfdesignersidecontractmanagement/client_contracts/add_milestone"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAllSubmessionFn = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allSubmissionId = getValidationsMsg.messageId

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/designer_work_submissions/view_submissions/?work_submission[contract_id]=${cardId}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getResourceLink = async () => {
    const headers = {
        "Content-Type": "application/json",
        token: tokenData,
    };

    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
      this.resourceLinkId = getValidationsMsg.messageId
   
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       `bx_block_cfdesignersidecontractmanagement/contract_resources/${cardId}`
      )
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area End
}