import React from "react";
// Customizable Area Start
import OfferContractController, {
  ContractDetailContractDataAttributes,
  ContractDetailProposalDataAttributes,
  ContractDetailContractDataAttributesMilestonesData,
  Props,
} from "./OfferContractController.web";
import {
  Box, Grid, Step, Dialog, DialogContent,
  StepLabel, Button, withStyles, Checkbox,
  StepContent, Divider, Typography, styled
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import StarIcon from '@material-ui/icons/Star';
import { StepperStyledComponentWithNumber } from '../../cfproposalmanagement/src/UnderReviewProposal.web'
import { CalenderIcon } from "../../../components/src/ProposalComponent";
import DeclinedModal from "../../../components/src/DeclinedModal.web";
import DeleteProjectModal from "../../../components/src/DeleteProjectModel.web"
import DeletePostModal from "../../../components/src/DeletePostModel.web";
import { closeIcon, deleteIcon } from "../../landingpage/src/assets";
import {leftArrowIcon} from '../../dashboard/src/assets';

import { Proposals } from "./Proposals.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {avatar} from "../../catalogue/src/assets"
const config = require("../../../framework/src/config.js")

const customStyle = require('../../dashboard/src/CustomStyles.js');
// Customizable Area End

export class OfferContract extends OfferContractController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPhoto = (mainPhoto: string, backgroundColor: string) => {
    let content;
    if (mainPhoto) {
      content = (
        <img style={{ width: "100px", height: "100px", borderRadius: "50%" }}
          src={`${config.baseURL}${mainPhoto}`}
        />
      );
    } else if (backgroundColor) {
      content = (
        <Box style={{ width: "100px", height: "100px", borderRadius: "50%", backgroundColor: backgroundColor }}></Box>
      );
    } else {
      content = (
        <img src={avatar} style={{ width: "100px", height: "100px", borderRadius: "50%" }} alt="avatar" />
      );
    }
    return <>
      {content}
    </>;
  }

  cardDetail = () => {
    const { contractDtailsProposal: { data } } = this.state;
    let final: ContractDetailProposalDataAttributes = {
      id: 0,
      created_at: "",
      updated_at: "",
      service_rate: 0,
      give_your_pitch: "",
      profile_photo: "",
      background_color: "",
      location: "",
      user_name: "",
      likes_count: 0,
      view_count: 0,
      rating: 0,
      received_time_ago: ""
    }
    if (data?.attributes) {
      final = data?.attributes;
    }
    return (
      <CardBox className="opportunity_card_main_containerChangeColorNew" data-test-id="navigate">
        <Box style={{ display: "flex", alignItems: "center", gap: "30px", padding: "2rem 1rem" }}>
          <Box>
            {this.renderPhoto(final.profile_photo, final.background_color)}
          </Box>
          <Box><Typography style={{
            ...webStyle.title,
            textTransform: "capitalize",
            width: "100%",
            wordBreak: "break-all"
          }}>{final.user_name}</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              color: "#FFF2E3",
              textTransform: "capitalize",
              paddingTop: "0.5rem"
            }}>{final.location}</Typography></Box>
        </Box>
        <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", padding: "0px 0.5rem" }}>
          <Box>
            <Typography style={webStyle.gray14}>Views</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textTransform: "capitalize",
              textAlign: "center"
            }}>{final.view_count}</Typography>
          </Box>
          <Box style={{ borderLeft: "1px solid grey", paddingLeft: "1rem" }}>
            <Typography style={webStyle.gray14}>Likes</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              textTransform: "capitalize",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textAlign: "center"
            }}>{final.likes_count}</Typography>
          </Box>
          <Box style={{ borderRight: "1px solid grey", borderLeft: "1px solid grey", padding: "0px 10px" }}>
            <Typography style={webStyle.gray14}>Submitted rate</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              textTransform: "capitalize",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textAlign: "center"
            }}>{final.service_rate.toLocaleString('en-IN')} pr/hr</Typography>
          </Box>
          <Box>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "16.94px",
              fontWeight: 400,
              color: "#7D7D7D",
              textTransform: "capitalize",
              textAlign: "center"
            }}>Ratings</Typography>
            <Box style={{
              display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px",
              height: "26px", width: "60px", background: "green", borderRadius: "3px", gap: "2px"
            }}>
              <Typography style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19.36px",
                textTransform: "capitalize",
                textAlign: "center",
                color: "#FFF2E3",
              }}>{final.rating}
              </Typography>
              <Box><StarIcon style={{ color: "white", width: "14px", height: "13px" }} /></Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ borderTop: "0.97px solid #545454", marginTop: "20px" }}>
          <Box style={{ padding: "1rem" }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "11px",
              lineHeight: "13.31px",
              fontWeight: 400,
              color: "#66625C",
              textTransform: "capitalize",
              textAlign: "left",
            }}>{final.received_time_ago}</Typography>
          </Box>
          <Box style={{
            padding: "1rem"
          }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 700,
              color: "#FFF2E3",
              lineHeight: "16.94px",
              textTransform: "capitalize",
              textAlign: "left",
            }}>Pitch</Typography>
          </Box>
          <Box style={{ padding: "1rem", height: "6rem" }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "16.94px",
              fontWeight: 400,
              color: "#FFF2E3",
              textTransform: "capitalize",
              textAlign: "left",
              display: "-webkit-box",
              WebkitLineClamp: 5,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordWrap: "break-word"

            }}>{final.give_your_pitch}</Typography>
          </Box>
        </Box>

      </CardBox>
    )
  }

  viewAllProposal = () => {
    return (
      <>
        {this.checkAcceptedStatus() &&
          <Box mt={2}>
            <Typography style={webStyle.font16WhiteUnderline} onClick={this.archivedProposal} data-test-id="displayAcceptOfferedProposal">
              View Archived Proposals
            </Typography>
          </Box>
        }
        {this.checkDeclinedEditStatus() &&
          <Box>
            <Typography style={webStyle.font16WhiteUnderline} onClick={this.savedMilestone} data-test-id="displayOfferedProposal">
              View offered contract
            </Typography>
          </Box>
        }
        {this.exceptDeclinedOfferAccept() &&
          <Box mt={2}>
            <Typography style={webStyle.font16WhiteUnderline} data-test-id="displayAllProposal" onClick={this.displayAllProposal}>
              View all Proposals
            </Typography>
          </Box>
        }
        {this.checkDeclinedOfferStatus() &&
          <Box mt={2}>
            <Typography style={webStyle.font16WhiteUnderline} onClick={this.savedMilestone} data-test-id="displayDeclineOfferedProposal">
              Saved contract milestones
            </Typography>
          </Box>
        }
      </>
    )
  }

  blankAttribute = () => {
    let final: ContractDetailContractDataAttributes = {
      id: 0,
      project_timeline: 0,
      proposal_id: 0,
      edited_start_date: '',
      work_opportunity_title: '',
      start_date: '',
      terms_and_condition_accept: false,
      decline_contract: false,
      project_timeline_type: '',
      contract_overall_budget: 0,
      project_rate_type: '',
      after_edit_project_timeline: 0,
      after_edits_overall_budget: 0,
      account_id: 0,
      created_at: "",
      updated_at: "",
      milestones: {
        data: []
      },
      resent_contract_sent_on_date: "",
      edit_request_n_date: "",
      awaing_action_till: "",
      take_action_until: "",
    }
    return final;
  }

  setpperFirst = (saved?:boolean) => {
    const { contractDtailsContract: { data } } = this.state;
    let final = this.blankAttribute()
    if (data?.attributes) { final = data?.attributes; }
    let editPlus = 0;
    const editCount = final.milestones.data.filter(
      (milestone) => milestone.attributes.edits != null
    ).length;
    return (
      final.milestones.data.map((label, index) => {
        const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
        const checkExapanded = isExpanded ? true : false
        if (label.attributes.edits != null) {
          editPlus = editPlus + 1;
        }
        return (
          index < 4 && <Step
            className={index !== final.milestones.data.length - 1 ? 'step-with-line' : ''}
            expanded={checkExapanded} key={index} >
            <StepLabel >
              <Box style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }} >
                <Box
                  data-test-id="handleAccordionToggle"
                  style={OfferReceivedWebStyle.accordianSummaryStyle}
                >
                  <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <Box
                      style={{
                        display: "flex",
                        gap: '6px',
                        alignItems: 'center',
                        flex: 1
                      }}>
                      <Typography style={{ ...webStyle.font16White, lineBreak: 'anywhere' }}>{label.attributes.name}</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                        {checkExapanded
                          ?
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandLessIcon
                              onClick={() => this.toggleTerm(index)}
                              data-test-id="expandIconLess"
                              style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                          :
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandMoreIcon
                              data-test-id="expandIcon" onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box
                      style={{ display: 'flex', gap: '40px' }}>
                      <Box style={{ display: 'flex', gap: '10px', marginLeft: '20px' }}>
                        {CalenderIcon()}
                        <Typography style={webStyle.font16White}>
                          {this.formatDateShort(label.attributes.due_date)}
                        </Typography>
                      </Box>
                      <Typography style={webStyle.font16White}>&#8377; {label.attributes.amount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: '10px' }}>
                  <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                    {label.attributes.description}
                  </Typography>
                </Box>
              </Box>
            </StepLabel>
            <StepContent>
              {checkExapanded &&
                <>
                  <Box style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                    <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                      <Typography className="deliveerable-style">Deliverables:</Typography>
                      {label.attributes.deliverables.data.map((item, index) => {
                        return (item.attributes.deliverable &&
                          <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                            {index + 1}.{" "}{item.attributes.deliverable}
                          </Typography>
                        )
                      })
                      }
                    </Box>
                  </Box>
                  {(!saved && label.attributes.edits) &&
                    <Box
                      style={webStyle.editGrayDiv}>
                      <Box
                        data-test-id="handleAccordionToggle"
                        style={OfferReceivedWebStyle.accordianSummaryStyle}
                      >
                        <Box>
                          <Typography style={webStyle.font14White400}>Requested edit ({editPlus}/{editCount})</Typography>
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px', marginTop: '20px' }}>
                          <Box
                            style={{
                              display: "flex",
                              gap: '6px',
                              alignItems: 'center',
                              flex: 1
                            }}>
                            <Typography style={{ ...webStyle.font16White, lineBreak: 'anywhere' }}>{label.attributes.edits?.data.attributes.name}</Typography>
                          </Box>
                          <Box
                            style={{ display: 'flex', gap: '40px' }}>
                            <Box
                              style={{ display: 'flex', gap: '10px', marginLeft: '20px' }}>
                              {
                                CalenderIcon()
                              }
                              <Typography style={webStyle.font16White}>
                                {
                                  this.formatDateShort(label.attributes.edits?.data.attributes.due_date)}</Typography>
                            </Box>
                            <Typography style={webStyle.font16White}>
                              &#8377; {label.attributes.edits?.data.attributes.amount}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box style={{
                        marginTop: '10px'
                      }}
                      >
                        <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>{label.attributes.edits?.data.attributes.description}</Typography>
                      </Box>
                      <Box style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                        <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                          <Typography className="deliveerable-style">Deliverables:</Typography>
                          {label.attributes.edits.data.attributes.edit_deliverables.data.map((item, index) => {
                            return (item.attributes.deliverable && <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                              {index + 1}.{" "}{item.attributes.deliverable}
                            </Typography>
                            )
                          })
                          }
                        </Box>
                      </Box>
                    </Box>
                  }
                </>
              }
            </StepContent>
          </Step>
        )
      }
      )
    )
  }

  stepperLast = (saved?:boolean) => {
    const { contractDtailsContract: {
      data
    } } = this.state;
    let final = this.blankAttribute()
    if (data?.attributes) {
      final = data?.attributes;
    }
    let editPlus = 0;
    const editCount = final.milestones.data.filter(
      (
        milestone
      ) => milestone.attributes.edits != null).length;
    return (
      this.state.moreMilestone && final.milestones.data.map((label, index) => {
        const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
        const checkExapanded = isExpanded ? true : false
        if (label.attributes.edits != null) {
          editPlus = editPlus + 1;
        }
        return (
          index > 3 &&
          <Step
            className={index !== final.milestones.data.length - 1 ? 'step-with-line' : ''}
            key={index}
            expanded={checkExapanded}>
            <StepLabel >
              <Box
                style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }} >
                <Box style={OfferReceivedWebStyle.accordianSummaryStyle}
                  data-test-id="handleAccordionToggle"
                >
                  <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <Box
                      style={{
                        gap: '6px',
                        alignItems: 'center',
                        display: "flex",
                        flex: 1
                      }}>
                      <Typography
                        style={{ ...webStyle.font16White, lineBreak: 'anywhere' }}>{label.attributes.name}</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                        {checkExapanded
                          ?
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandLessIcon onClick={() => this.toggleTerm(index)}
                              style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                          :
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandMoreIcon onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', gap: '40px' }}>
                      <Box
                        style={{
                          display: 'flex',
                          gap: '10px',
                          marginLeft: '20px'
                        }}>
                        {CalenderIcon()}
                        <Typography style={webStyle.font16White}>{this.formatDateShort(label.attributes.due_date)}</Typography>
                      </Box>
                      <Typography style={webStyle.font16White}>
                        &#8377; {label.attributes.amount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{
                  marginTop: '10px'
                }}
                >
                  <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                    {label.attributes.description}</Typography>
                </Box>
              </Box>
            </StepLabel>
            <StepContent>
              {checkExapanded &&
              <>
                <Box
                  style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                  <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography
                      className="deliveerable-style">
                      Deliverables:
                    </Typography>
                    {label.attributes.deliverables.data.map((item, index) => {
                      return (item.attributes.deliverable && <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>{index + 1}.{" "}{item.attributes.deliverable}
                      </Typography>
                      )
                    })
                    }
                  </Box>
                </Box>
                {(!saved && label.attributes.edits) &&
                  <Box style={webStyle.editGrayDiv}>
                    <Box
                      style={OfferReceivedWebStyle.accordianSummaryStyle}
                      data-test-id="handleAccordionToggle"
                    >
                      <Box>
                        <Typography style={webStyle.font14White400}>Requested edit ({editPlus}/{editCount})</Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px', marginTop: '20px' }}>
                        <Box
                          style={{
                            alignItems: 'center',
                            gap: '6px',
                            display: "flex",
                            flex: 1
                          }}>
                          <Typography style={{ ...webStyle.font16White, lineBreak: 'anywhere' }}>{label.attributes.edits?.data.attributes.name}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: '40px' }}>
                          <Box
                            style={{
                              display: 'flex',
                              gap: '10px', marginLeft: '20px'
                            }}>
                            {CalenderIcon()}
                            <Typography style={webStyle.font16White}>
                              {this.formatDateShort(label.attributes.edits?.data.attributes.due_date)}
                            </Typography>
                          </Box>
                          <Typography style={webStyle.font16White}>
                            &#8377; {label.attributes.edits?.data.attributes.amount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={{ marginTop: '10px' }}>
                      <Typography
                        style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}
                      >
                        {label.attributes.edits?.data.attributes.description}
                      </Typography>
                    </Box>
                    <Box
                      style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                      <Box
                        style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                        <Typography className="deliveerable-style">Deliverables:</Typography>
                        {label.attributes.edits.data.attributes.edit_deliverables.data.map((item, index) => {
                          return (item.attributes.deliverable &&
                            <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>{index + 1}.{" "}{item.attributes.deliverable}
                            </Typography>
                          )
                        })
                        }
                      </Box>
                    </Box>
                  </Box>
                }
                </>
                }
            </StepContent>
          </Step>
        )
      }
      )
    )
  }

  stepperDetail = () => {
    const { contractDtailsContract: { data } } = this.state;
    let final = this.blankAttribute()
    if (data?.attributes) {
      final = data?.attributes;
    }
    const length = final.milestones.data.length;
    return (
      <StepperStyledComponentWithNumber
        style={{
          padding: '40px 0'
        }}
        activeStep={0} orientation="vertical" className="about-under-review-second-box-stepper-transparent"
      >
        {this.setpperFirst()}
        {!this.state.moreMilestone && length - 4 > 0 &&
          <Step>
            <StepLabel
              StepIconComponent={() => (
                <CustomIconContainer>
                  <Typography
                    style={{ fontSize: '14px', fontWeight: 'normal' }} variant="caption" component="div">
                    {`+${length - 4}`}
                  </Typography>
                </CustomIconContainer>
              )}
            >
              <Typography data-test-id="checkMoreMilestone" onClick={this.checkMoreMilestone} style={{ marginTop: '3px', textDecoration: 'underline', cursor: 'pointer' }}>{length - 4}  more milestones</Typography>
            </StepLabel>
          </Step>
        }
        {this.stepperLast()}
      </StepperStyledComponentWithNumber>
    )
  }

  modalName = (label: ContractDetailContractDataAttributesMilestonesData) => {
    return (
      <Typography style={{ ...webStyle.font16White, lineBreak: 'anywhere' }}>
        {label.attributes.edits ?
          label.attributes.edits.data.attributes.name
          :
          label.attributes.name}
      </Typography>
    )
  }

  dueDate = (label: ContractDetailContractDataAttributesMilestonesData) => {
    return (
      <Typography style={webStyle.font16White}>
        {this.formatDateShort(label.attributes.edits ?
          label.attributes.edits.data.attributes.due_date
          :
          label.attributes.due_date
        )}
      </Typography>
    )
  }

  amountData = (label: ContractDetailContractDataAttributesMilestonesData) => {
    return (
      <Typography style={webStyle.font16White}>&#8377; {label.attributes.edits ?
        label.attributes.edits.data.attributes.amount
        :
        label.attributes.amount
      }
      </Typography>
    )
  }

  descriptionData = (label: ContractDetailContractDataAttributesMilestonesData) => {
    return (
      <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
        {label.attributes.edits ?
          label.attributes.edits.data.attributes.description
          :
          label.attributes.description
        }
      </Typography>
    )
  }

  setpperFirstModal = () => {
    const { contractDtailsContract: { data } } = this.state;
    let final = this.blankAttribute()
    if (data?.attributes) { final = data?.attributes; }
    let editPlus = 0;
    return (
      final.milestones.data.map((label, index) => {
        const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
        const checkExapanded = isExpanded ? true : false
        if (label.attributes.edits != null) {
          editPlus = editPlus + 1;
        }
        return (
          index < 4 &&
          <Step
            className={
              index !== final.milestones.data.length - 1 ?
                'step-with-line'
                :
                ''
            }
            expanded={checkExapanded} key={index}
          >
            <StepLabel>
              <Box
                style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }} >
                <Box data-test-id="handleAccordionToggle"
                  style={OfferReceivedWebStyle.accordianSummaryStyle}
                >
                  <Box style={{
                    display: "flex", alignItems: "center",
                    width: '100%', justifyContent: 'space-between', marginBottom: '10px'
                  }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: 'center',
                        gap: '6px',
                        flex: 1
                      }}>
                      {this.modalName(label)}
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                        {checkExapanded
                          ?
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandLessIcon
                              onClick={() => this.toggleTerm(index)}
                              data-test-id="expandIconLess"
                              style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }}
                            />
                          </Box>
                          :
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandMoreIcon
                              data-test-id="expandIcon" onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }}
                            />
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        gap: '40px'
                      }}>
                      <Box style={{ display: 'flex', gap: '10px', marginLeft: '20px' }}>
                        {CalenderIcon()}
                        {this.dueDate(label)}
                      </Box>
                      {this.amountData(label)}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: '10px' }}>
                  {this.descriptionData(label)}
                </Box>
              </Box>
            </StepLabel>
            <StepContent>
              {checkExapanded &&
                <Box style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                  <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography className="deliveerable-style">Deliverables:</Typography>
                    {
                      label.attributes.edits ?
                        label.attributes.edits.data.attributes.edit_deliverables.data.map((item, index) => {
                          return (item.attributes.deliverable &&
                            <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                              {index + 1}.{" "}{item.attributes.deliverable}
                            </Typography>
                          )
                        })
                        :
                        label.attributes.deliverables.data.map((item, index) => {
                          return (<Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                            {index + 1}.{" "}{item.attributes.deliverable}
                          </Typography>
                          )
                        })
                    }
                  </Box>
                </Box>}
            </StepContent>
          </Step>
        )
      }
      )
    )
  }

  stepperLastModal = () => {
    const { contractDtailsContract: {
      data
    } } = this.state;
    let final = this.blankAttribute()
    if (data?.attributes) {
      final = data?.attributes;
    }
    let editPlus = 0;
    return (
      this.state.moreMilestone && final.milestones.data.map((label, index) => {
        if (label.attributes.edits != null) { editPlus = editPlus + 1 }
        const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
        const checkExapanded = isExpanded ? true : false
        return (
          index > 3 &&
          <Step
            key={index}
            className={index !== final.milestones.data.length - 1
              ? 'step-with-line' : ''}
            expanded={checkExapanded}>
            <StepLabel >
              <Box
                style={
                  { background: "transparent", color: "white", fontFamily: "inter", width: '100%' }
                } >
                <Box
                  data-test-id="handleAccordionToggle"
                  style={OfferReceivedWebStyle.accordianSummaryStyle}
                >
                  <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <Box
                      style={{
                        alignItems: 'center',
                        gap: '6px',
                        display: "flex",
                        flex: 1
                      }}>
                      {
                        this.modalName(label)
                      }
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                        {checkExapanded
                          ?
                          <Box
                            style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandLessIcon onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                          :
                          <Box
                            style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandMoreIcon
                              onClick={() => this.toggleTerm(index)}
                              style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: 'flex', gap: '40px'
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          gap: '10px', marginLeft: '20px'
                        }}>
                        {CalenderIcon()}
                        {
                          this.dueDate(label)
                        }
                      </Box>
                      {
                        this.amountData(label)
                      }
                    </Box>
                  </Box>
                </Box>
                <Box style={{
                  marginTop: '10px'
                }}
                >
                  {
                    this.descriptionData(label)
                  }
                </Box>
              </Box>
            </StepLabel>
            <StepContent>
              {checkExapanded &&
                <Box
                  style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                  <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography
                      className="deliveerable-style">
                      Deliverables:
                    </Typography>
                    {
                      label.attributes.edits ?
                        label.attributes.edits.data.attributes.edit_deliverables.data.map((item, index) => {
                          return (
                            <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>{index + 1}.{" "}{item.attributes.deliverable}</Typography>
                          )
                        })
                        :
                        label.attributes.deliverables.data.map((item, index) => {
                          return (item.attributes.deliverable && <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>{index + 1}.{" "}{item.attributes.deliverable}</Typography>
                          )
                        })
                    }
                  </Box>
                </Box>}
            </StepContent>
          </Step>
        )
      }
      )
    )
  }

  stepperDetailModal = () => {
    const { contractDtailsContract: { data } } = this.state;
    let final = this.blankAttribute()
    if (data?.attributes) {
      final = data?.attributes;
    }
    const length = final.milestones.data.length;
    return (
      <StepperStyledComponentWithNumber
        style={{
          padding: '20px 0 0'
        }}
        activeStep={0} orientation="vertical"
        className="about-under-review-second-box-stepper-transparent" >
        {this.setpperFirstModal()}
        {!this.state.moreMilestone && length - 4 > 0 &&
          <Step>
            <StepLabel
              StepIconComponent={() => (
                <CustomIconContainer>
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {`+${length - 4}`}
                  </Typography>
                </CustomIconContainer>
              )}
            >
              <Typography data-test-id="checkMoreMilestone" onClick={this.checkMoreMilestone}
                style={{ marginTop: '3px', textDecoration: 'underline', cursor: 'pointer' }}>
                {length - 4}  more milestones
              </Typography>
            </StepLabel>
          </Step>
        }
        {this.stepperLastModal()}
      </StepperStyledComponentWithNumber>
    )
  }

  stepperDetailModalSaved = () => {
    const { contractDtailsContract: { data } } = this.state;
    let final = this.blankAttribute()
    if (data?.attributes) {
      final = data?.attributes;
    }
    const length = final.milestones.data.length;
    return (
      <StepperStyledComponentWithNumber
        style={{
          padding: '20px 28px 00px'
        }}
        activeStep={0}
        orientation="vertical" className="about-under-review-second-box-stepper-transparent"
      >
        {this.setpperFirst(true)}
        {!this.state.moreMilestone && length - 4 > 0 &&
          <Step>
            <StepLabel StepIconComponent={() => (
              <CustomIconContainer>
                <Typography variant="caption"
                  component="div" style={{ fontSize: '14px', fontWeight: 'normal' }}>
                  {`+${length - 4}`}
                </Typography>
              </CustomIconContainer>
            )}
            >
              <Typography
                data-test-id="checkMoreMilestone"
                onClick={this.checkMoreMilestone} style={{ marginTop: '3px', textDecoration: 'underline', cursor: 'pointer' }}>{length - 4}  more milestones</Typography>
            </StepLabel>
          </Step>
        }
        {this.stepperLast(true)}
      </StepperStyledComponentWithNumber>
    )
  }

  titleCases = () => {
    return (
      <>
        {this.checkAwaitRequestStatus() &&
          <Typography
            style={this.state.exceedTime ? webStyle.font36Gray : webStyle.font36White}>{this.state.milestoneStatusCheck ? this.getFormattedText() : this.state.contractAllDetails.status}
          </Typography>
        }
        {
          this.checkDeclinedStatus() &&
          <Typography
            style={this.state.exceedTime ? webStyle.font36Gray : webStyle.font36Red}>{this.state.contractAllDetails.status}
          </Typography>
        }
        {
          this.checkAcceptedStatus() &&
          <Typography
            style={this.state.exceedTime ? webStyle.font36Gray : webStyle.font36Green}>{this.state.contractAllDetails.status}
          </Typography>
        }
        {
          this.checkNoResponse() &&
          <Typography
            style={this.state.exceedTime ? webStyle.font36Gray : webStyle.font36Red}>{this.state.contractAllDetails.status}
          </Typography>
        }
      </>
    )
  }

  titleBelowHeading = () => {
    return (
      <>
        {this.checkAwaitRequestStatus() &&
          (!this.state.milestoneStatusCheck ?
            <Box
              style={{
                margin: "20px 0px 20px"
              }}>
              <Typography
                style={webStyle.font16White}>Deadline: {this.convertDateFormat(this.state.contractDtailsContract.data?.attributes.awaing_action_till)}
              </Typography>
            </Box>
            :
            <Box
              style={{
                margin: "20px 0px 20px"
              }}>
              <Typography
                style={webStyle.font16WhiteUnderline}>View edits
              </Typography>
            </Box>
          )}

        {this.checkDeclinedStatus() &&
          <Box
            style={{
              margin: "20px 0px 20px"
            }}>
            <Typography
              style={webStyle.gray12}>Declined on {this.formatDateShort(this.state.contractDtailsContract.data?.attributes.contract_status?.updated_at)}</Typography>
          </Box>
        }

        {this.checkAcceptedStatus() &&
          <Box
            style={{
              margin: "20px 0px 20px"
            }}>
            <Typography
              style={webStyle.gray12}>Accepted on {this.formatDateShort(this.state.contractDtailsContract.data?.attributes.contract_status?.updated_at)}. Activate within 72 hours</Typography>
          </Box>
        }

        {this.checkNoResponse() &&
          <Box
            style={{
              margin: "20px 0px 20px"
            }}>
            <Typography
              style={webStyle.gray12}>The designer did not reespond within 72 hours</Typography>
          </Box>
        }
      </>
    )
  }

  acceptEditButton = () => {
    return (
      <>
        {this.checkAcceptedStatus() &&
          <CustomBox className="button-flex">
            <CustomButton data-test-id='create-work' onClick={this.activeContract}
              className={`button-elm secondary-btn medium-text`}>Activate contract</CustomButton>
            <CustomBox className="action-text">
              <span style={webStyle.font14White400Line20}>Take action until {this.convertDateFormat(this.state.contractDtailsContract.data?.attributes.awaing_action_till)}</span>
            </CustomBox>
          </CustomBox>
        }</>
    )
  }

  acceptDeclinedButton = () => {
    return (
      <>
        {this.checkMilestoneString(this.state.contractAllDetails.status) &&
          <CustomBox className="button-flex">
            <CustomButton
              data-test-id='save-draft'
              className={`button-elm  draft-btn default-btn medium-text`}
              onClick={this.handleDeleteModel}
            >Decline</CustomButton>
            <CustomButton data-test-id='create-work'
              onClick={() => this.handleAcceptModel()}
              className={`button-elm secondary-btn medium-text`}>Accept edits</CustomButton>
            <CustomBox className="action-text">
              <span style={webStyle.font14White400Line20}>Take action until {this.convertDateFormat(this.state.contractDtailsContract.data?.attributes.awaing_action_till)}</span>
            </CustomBox>
          </CustomBox>
        }
      </>
    )
  }

  noResponseAny = () => {
    return (
      <>
        {this.state.exceedTime &&
          <CustomBox className="padding-right-15">
            <CustomBox className="no-offer">
              <CustomBox className="noOffer-text-wrap">
                {this.state.takenAction == "designer" &&
                  <Typography style={webStyle.font14Red400Line20}>No response was given by the designer by {this.convertToISTAndFormat(this.state.contractAllDetails.deadline)}</Typography>
                }
                {this.state.takenAction == "client" &&
                  <Typography style={webStyle.font14Red400Line20}>No action was taken by you until {this.convertToISTAndFormat(this.state.contractDtailsContract.data?.attributes.awaing_action_till)}</Typography>
                }
                <Typography className="top-8" style={webStyle.gray12}>Resending new offer for this post will move it into open opportunity section. You can send a contract to the selected or a new designer.</Typography>
              </CustomBox>
              <CustomBox
                className='discard-container'
                data-test-id='discard-container'
                onClick={this.handleDeleteProjectModel}
              >
                <img src={`${deleteIcon}`} alt='delete icon' />
                <Typography className='discard-text'>Delete post</Typography>
              </CustomBox>
              <CustomButton data-test-id='create-work' style={{ width: '270px' }} onClick={this.handleResendModel}
                className={`button-elm secondary-btn medium-text`}>Re-send offer</CustomButton>
            </CustomBox>
          </CustomBox>
        }
      </>
    )
  }

  deleteResendButton = () => {
    return (
      <>
        {this.checkDeclinedStatus() &&
          <CustomBox className="padding-right-15">
            <CustomBox className="button-flex-delete">
              <CustomBox
                className='discard-container'
                data-test-id='discard-container'
                onClick={this.handleDeleteProjectModel}
              >
                <img src={`${deleteIcon}`} alt='delete icon' />
                <Typography className='discard-text'>Delete post</Typography>
              </CustomBox>
              <CustomButton data-test-id='create-work' onClick={this.handleResendModel}
                className={`button-elm secondary-btn medium-text`}>Re-send offer</CustomButton>
            </CustomBox>
            <CustomBox className="declined-text">
              <span style={webStyle.gray12}>Resending new offer for this post will move it into open opportunity section </span>
            </CustomBox>
          </CustomBox>
        }
      </>
    )
  }

  renderDeleteModal = () => {
    return (
      <DeletePostModal
        data-test-id="deleteModel"
        open={this.state.resendModal}
        onClose={this.handleResendModel}
        onDelete={this.resendPostAPI}
        title="Re-send offer"
        cardTitle={this.state.loadDataAttribute.title}
        rsValue={(Number(this.state.loadDataAttribute.rateData || this.state.loadDataAttribute.overallRate)).toLocaleString('en-IN')}
        rsTitle={this.state.loadDataAttribute.rateData ? "pr/hr" : "overall"}
        month={this.state.loadDataAttribute.project_timeline}
        weekMonth={this.state.loadDataAttribute.project_timeline_type}
        description={this.state.loadDataAttribute.description}
        postedTime={this.state.loadDataAttribute.Posted_time_ago}
        descriptionBottom="Selecting re-send offer will let you select from all the proposals. The post will be moved to open opportunity section and the contract milestones will be saved."
        button1Text="Cancel"
        button2Text="Re-open post"
      />
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div>
        <Box>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Typography
              data-testid="allProposals"
              onClick={this.navigateViewWork}
              style={webStyle.font16WhiteUnderline}>View work details</Typography>

            {this.state.contractDtailsContract.data?.attributes.resent_contract_sent_on_date &&
              <Typography style={webStyle.gray12}>Contract sent on {this.convertDateFormat(this.state.contractDtailsContract.data?.attributes.resent_contract_sent_on_date)}</Typography>
            }
          </Box>

          <Box style={webStyle.editTitleButtonWrap}>
            <Box>
              <Box>
                {this.titleCases()}
              </Box>
                {this.titleBelowHeading()}
            </Box>
            {this.deleteResendButton()}
            {!this.state.exceedTime && this.acceptDeclinedButton()}
            {this.noResponseAny()}
            {!this.state.exceedTime && this.acceptEditButton()}
          </Box>

          <Divider style={webStyle.dividerStyle} />
        </Box>

        {this.state.contentType === "awaiting" &&
          <Box sx={webStyle.paper}>
            <Box>
              <Grid container>
                <Grid item xl={4} lg={4} sm={6} xs={12}
                  style={{ borderRight: "1px solid rgba(84, 84, 84, 1)", paddingRight: '40px' }}>
                  <Box
                    style={{
                      marginTop: "45px"
                    }}>
                    <Typography
                      style={webStyle.title}>Selected Proposal
                    </Typography>
                  </Box>
                  {this.cardDetail()}
                  {this.viewAllProposal()}
                </Grid>
                <Grid
                  item
                  xl={8}
                  lg={8}
                  sm={6}
                  xs={12}
                >
                  <Box style={{ padding: '20px' }}>
                    <Box style={!this.state.milestoneStatusCheck ? webStyle.opportunity_card_price_duration_box : webStyle.opportunity_card_price_duration_box_new}>
                      <Box style={!this.state.milestoneStatusCheck ? webStyle.titleDateWrap : webStyle.titleDateWrapNew}>
                        <Box>
                          <Typography
                            style={webStyle.title}>Contract Milestones</Typography>
                        </Box>
                        {this.state.milestoneStatusCheck &&
                          <Box>
                            <span style={webStyle.font14White}>Start date:</span>{"   "}<span style={webStyle.font16White}>{this.formatDateShort(this.state.contractDtailsContract.data?.attributes.start_date)}</span>
                          </Box>
                        }
                      </Box>
                      <Box display='flex' alignItems='flex-end' gridGap={40}>
                        {(this.checkAwaitingStatus() || this.checkAcceptedStatus()) &&
                          <Box>
                            <span style={webStyle.font14White}>Start date:</span>{"   "}<span style={webStyle.font16White}>{this.formatDateShort(this.state.contractDtailsContract.data?.attributes.start_date)}</span>
                          </Box>
                        }

                        {this.state.milestoneStatusCheck &&
                          <Box display="flex" flexDirection="column">
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} pt={0} pr={2} pb={0} pl={2}>
                              <Typography style={{ width: '35%' }}></Typography>
                              <Typography style={webStyle.gray12}>Project timeline</Typography>
                              <Typography style={webStyle.gray12}>Overall budget:</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} gridGap={25} pt={0} pr={2} pb={0} pl={2}>
                              <Typography style={webStyle.font14White400}>Initial contract offer</Typography>
                              <Box display="flex" alignItems="center" gridGap={40}>
                                <Box bgcolor="#363636" color="#FFB9C7" px={1.5} py={0.5} borderRadius={16} mr={1}>
                                  <Typography style={webStyle.font14600}>{this.state.contractDtailsContract.data?.attributes.project_timeline} {this.state.contractDtailsContract.data?.attributes.project_timeline_type}</Typography>
                                </Box>
                                <Box bgcolor="#363636" color="#1CBAE0" px={1.5} py={0.5} borderRadius={16}>
                                  <Typography style={webStyle.font14600}>&#8377; {this.state.contractDtailsContract.data?.attributes.contract_overall_budget}</Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ border: '1px solid #545454', borderRadius: '8px' }} gridGap={25} pt={1} pr={2} pb={1} pl={2}>
                              <Typography style={webStyle.font14White400}>With requested edits</Typography>
                              <Box display="flex" alignItems="center" gridGap={40}>
                                <Box bgcolor="#363636" color="#FFB9C7" px={1.5} py={0.5} borderRadius={16} mr={1}>
                                  <Typography style={webStyle.font14600}>{this.state.contractDtailsContract.data?.attributes.after_edit_project_timeline} {this.state.contractDtailsContract.data?.attributes.project_timeline_type}</Typography>
                                </Box>
                                <Box bgcolor="#363636" color="#1CBAE0" px={1.5} py={0.5} borderRadius={16}>
                                  <Typography style={webStyle.font14600}>&#8377; {this.state.contractDtailsContract.data?.attributes.after_edits_overall_budget}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        }

                        {this.checkMilestoneTimeline() &&
                          <Box display="flex" flexDirection="column">
                            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={25} mb={2} pt={0} pr={2} pb={0} pl={2}>
                              <Typography style={webStyle.gray12}>Project timeline</Typography>
                              <Typography style={webStyle.gray12}>Overall budget:</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={25} pt={0} pr={2} pb={0} pl={2}>
                              <Box display="flex" alignItems="center" gridGap={25}>
                                <Box bgcolor="#363636" color="#FFB9C7" px={1.5} py={0.5} borderRadius={16} mr={1}>
                                  <Typography style={webStyle.font14600}>{this.state.contractDtailsContract.data?.attributes.project_timeline} {this.state.contractDtailsContract.data?.attributes.project_timeline_type}</Typography>
                                </Box>
                                <Box bgcolor="#363636" color="#1CBAE0" px={1.5} py={0.5} borderRadius={16} mr={1}>
                                  <Typography style={webStyle.font14600}>&#8377; {this.state.contractDtailsContract.data?.attributes.contract_overall_budget}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box style={{ marginTop: '40px' }}>
                      {this.stepperDetail()}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }

        {this.state.contentType === "savedMilestone" &&
          <Box>
            <Box style={webStyle.savedMilestoneWrap}>
              <Box data-testid="savedArrow"
                style={webStyle.allPropsCon}>
                <img style={{ cursor: 'pointer' }} onClick={this.displayAwaiting} src={leftArrowIcon} alt="left-icon" />
                <Typography style={{ ...webStyle.title, marginLeft: '15px' }}>Saved contract milestones</Typography>
              </Box>
              <Typography style={{ ...webStyle.font14White400, maxWidth: '885px', width: '100%' }}>The saved milestone breakup with amounts and deliverables can be used again when offering contract to a new designer. This can be accessed in your work details section and will be prompted to you when creating a new offer for this same opportunity</Typography>
            </Box>
            <Box style={webStyle.modalStepBoxNew}>
              <Box style={webStyle.editTitleButtonWrapNew}>
                <Typography
                  style={webStyle.title}>Contract Milestones
                </Typography>
                <Box display="flex" gridGap={40} alignItems="flex-end">
                  <Box>
                    <span style={webStyle.font14White}>Start date:</span>{"   "}<span style={webStyle.font16Gray}>{this.formatDateShort(this.state.contractDtailsContract.data?.attributes.start_date)}</span>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={25} mb={2} pt={0} pr={2} pb={0} pl={2}>
                      <Typography style={webStyle.gray12}>Project timeline</Typography>
                      <Typography style={webStyle.gray12}>Overall budget:</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={25} pt={0} pr={2} pb={0} pl={2}>
                      <Box display="flex" alignItems="center" gridGap={25}>
                        <Box bgcolor="#363636" color="#FFB9C7" px={1.5} py={0.5} borderRadius={16} mr={1}>
                          <Typography style={webStyle.font14600}>{this.state.contractDtailsContract.data?.attributes.project_timeline} {this.state.contractDtailsContract.data?.attributes.project_timeline_type}</Typography>
                        </Box>
                        <Box bgcolor="#363636" color="#1CBAE0" px={1.5} py={0.5} borderRadius={16} mr={1}>
                          <Typography style={webStyle.font14600}>&#8377; {this.state.contractDtailsContract.data?.attributes.contract_overall_budget}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider style={{ ...webStyle.dividerStyle, marginTop: '30px' }} />
              {this.stepperDetailModalSaved()}
            </Box>
          </Box>
        }

        {this.state.contentType === "proposalList" &&
          <Proposals data-test-id="proposal" cardId={this.props.cardId} type={this.state.archievedContect} onOfferAction={this.displayAwaiting} dropDownId={this.props.dropDownId} navigation={this.props.navigation} displayTitle={false} />
        }

        <DeclinedModal
          data-test-id="declineModel"
          open={this.state.deleteModal}
          onClose={this.handleDeleteModel}
          onDelete={this.handleShowDiscard}
          title="Decline edits"
          content="Are you sure you want to decline the edits? 
                    The contract will get closed with this designer once you decline their edits."
          button1Text="Cancel"
          button2Text="Decline edits"
        />

        <DeleteProjectModal
          data-test-id="deleteProjectModel"
          open={this.state.deleteProjectModal}
          onClose={this.handleDeleteProjectModel}
          onDelete={this.handleDeletePost}
          title="Delete Post"
          content="Are you sure you want to delete this post permanently?"
          button1Text="Cancel"
          button2Text="Delete"
        />

        {this.renderDeleteModal()}

        <DioalogComponent
          open={this.state.acceptModel}
          onClose={this.handleAcceptModel}
          fullWidth={true}
          data-test-id="close-onclick"
          style={{
            scrollbarWidth: 'none'
          }}
          maxWidth="sm"
          >
          <Box style={{ height: 40, display: 'flex', justifyContent: 'flex-end' }}>
            <img
              style={{ cursor: 'pointer', width: '14px', height: '14px' }}
              src={closeIcon}
              data-test-id="closeButton"
              alt="Close"
              onClick={() => this.handleAcceptModel()}
            />
          </Box>
          <DialogContent
            style={{
              padding: '40px 30px',
              borderRadius: '10px',
              border: '1px solid rgba(95, 95, 95, 1)',
            }}
          >
            <Box style={{ ...webStyle.editTitleButtonWrap, width: '100%', marginTop: '0', alignItems: 'flex-end' }}>
              <Box>
                <Typography
                  style={webStyle.title}>Accept edits
                </Typography>
                <Typography
                  style={{ ...webStyle.gray14, marginTop: '8px' }}>{this.state.contractDtailsContract.data?.attributes.work_opportunity_title}
                </Typography>
              </Box>
              <Box>
                <span style={webStyle.font14White}>Start date:</span>{"   "}<span style={webStyle.font16White}>{this.state.contractDtailsContract.data?.attributes.start_date}</span>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} pt={0} pr={2} pb={0} pl={2}>
                  <Typography style={webStyle.gray12}>Project timeline</Typography>
                  <Typography style={webStyle.gray12}>Overall budget:</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={25} pt={0} pr={2} pb={0} pl={2}>
                  <Box display="flex" alignItems="center" gridGap={40}>
                    <Box bgcolor="#363636" color="#FFB9C7" px={1.5} py={0.5} borderRadius={16} mr={1}>
                      <Typography style={webStyle.font14600}>{this.state.contractDtailsContract.data?.attributes.project_timeline} {this.state.contractDtailsContract.data?.attributes.project_timeline_type}</Typography>
                    </Box>
                    <Box bgcolor="#363636" color="#1CBAE0" px={1.5} py={0.5} borderRadius={16}>
                      <Typography style={webStyle.font14600}>&#8377; {this.state.contractDtailsContract.data?.attributes.contract_overall_budget}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={webStyle.modalStepBox}>
              <Typography
                style={webStyle.title}>Accept edits
              </Typography>
              {this.stepperDetailModal()}
            </Box>
            <Box style={webStyle.modalBottomButtonWrap}>
              <CustomBox style={{ display: 'flex', gap: '12px', alignItems: "center" }}>
                <CustomCheckBox data-test-id='customCheckbox' checked={this.state.conditionChecked} onClick={() => this.toggleCondition()} style={{ backgroundColor: this.handleCheckBg(), padding: '0' }} />
                <Typography style={webStyle.font14White400}>I agree to all terms and conditions</Typography>
              </CustomBox>
              <Button className="btn-send-offer"
                data-test-id="btn-send-offer"
                disabled={!this.enablePostWorkOpportunity()}
                onClick={this.handleAcceptContractClick}
                style={{
                  background:
                    (!this.enablePostWorkOpportunity()) ? "rgba(255, 242, 227, 0.51)" : "rgba(255, 242, 227, 1)"
                }}
              >
                Activate contract
              </Button>
            </Box>
          </DialogContent>
        </DioalogComponent>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CardBox = styled(Box)({
  maxWidth: "398px",
  width: '100%',
  margin: "18px 0",
  borderRadius: "11.54px",
  position: 'relative',
  overflow: 'hidden',
  "&.opportunity_card_main_containerChangeColorNew": {
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '13px',
      padding: '2px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      WebkitMaskComposite: 'xor',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      maskComposite: 'exclude',
    }

  },
  "@media (max-width:960px)": {
      height: "533px",
  },
  "@media (min-width:720px)": {

  },
});

const OfferReceivedWebStyle = {
  accordianSummaryStyle: {
    fontSize: "20px",
    fontWeight: 700,
    margin:0, 
    width: '100%' as '100%',
    borderBottom: "1px solid rgba(84, 84, 84, 1)",
    lineHeight: "24.2px",
    textAlign: "left" as 'left',
    color: "#fff2e3",
    paddingLeft: "0px" as '0px',
  },
  downloadIconStyle: {
    borderRadius: '50%',
    background: "rgba(84, 84, 84, 1)",
    width: '30px', height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
}

const CustomIconContainer = styled('div')(({ theme }) => ({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "#FFF2E3",
  color: 'rgba(17, 17, 17, 1)',
}));

const DioalogComponent = styled(Dialog)({
  '& .MuiPaper-root':{
    backgroundColor:'unset',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '938px',
  },
  '& .MuiPickersLayout-contentWrapper': {
    background: 'rgba(34, 34, 34, 1) '

  },
  '& .MuiDialogContent-root': {
    paddingInline: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgba(34, 34, 34, 1)'
  }
})

const CustomCheckBox = withStyles({
  root: {
    color: '#d9d9d9',
    width: '20px',
    height: '20px',
    borderRadius: '2.3px',
    border: 'none'
  },
  checked: {
    '&.Mui-checked': {
      color: '#111111'
    }
  },
})(Checkbox);

const CustomButton = withStyles({
  root: {
    ...customStyle.customStyle.buttonField
  }
})(Button);

const CustomBox = withStyles((theme) => ({
  root: {
    '&.button-flex': {
      display: 'flex',
      gap: '17px',
      marginTop: '45px',
      '& .medium-text': {
        fontFamily: 'Inter-Medium, sans-serif !important',
        padding: '10px 30px'
      }
    },
    '&.button-flex-delete': {
      display: 'flex',
      gap: '40px',
      '& .medium-text': {
        fontFamily: 'Inter-Medium, sans-serif !important',
        padding: '10px 30px'
      }
    },
    '&.no-offer':{
      display: 'flex',
      alignItems: 'center',
      padding: '30px',
      backgroundColor: '#222222',
      borderRadius: '14px',
      gap: '40px',
      '& .medium-text': {
        fontFamily: 'Inter-Medium, sans-serif !important',
        padding: '10px 30px'
      },
      '& .medium-text-new': {
        fontFamily: 'Inter-Medium, sans-serif !important',
        padding: '10px 20px'
      }
    },
    '&.noOffer-text-wrap': {
      maxWidth: '443px',
      width: '100%',
      '& .top-8':{
        marginTop: '8px'
      }
    },
    '&.action-text': {
      maxWidth: '160px',
      width: '100%'
    },
    '&.declined-text': {
      maxWidth: '300px',
      width: '100%',
      marginTop: '15px'
    },
    '&.discard-container': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '&.discard-container .discard-text': {
      fontSize: '16px',
      fontFamily: 'Inter-SemiBold, sans-serif',
      lineHeight: '19.36px',
      color: '#fff2e3',
      borderBottom: '1px solid #fff2e3',
      width: 'max-content',
      marginLeft: '11px',
    },
  },
}))(Box);

const webStyle: Record<string, CSSProperties> = {
  dividerStyle: {
    backgroundColor: "rgba(84, 84, 84, 1)",
  },
  paper: {
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
  opportunity_card_price_duration_box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  opportunity_card_price_duration_box_new:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  opportunity_card_price_duration: {
    padding: "6px 16px 6px 16px",
    fontSize: "14px",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    fontFamily: "Inter,sans-serif",
    color: "#1CBAE0",
    fontWeight: 600,
  },
  opportunity_card_duration_color: {
    padding: "6px 16px 6px 16px",
    fontSize: "14px",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    fontFamily: "Inter,sans-serif",
    color: "#FFB9C7",
    fontWeight: 600,
  },
  titleCardWrap: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
  },
  gray12: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.52px',
    color: '#7D7D7D'
  },
  gray14: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.94px",
    textTransform: "capitalize",
    color: "#7D7D7D",
    textAlign: "left"
  },
  font14White: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
    color: '#FFF2E3'
  },
  font16Gray: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#545454'
  },
  font16White: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3'
  },
  font16WhiteUnderline: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  flexGap50: {
    display: 'flex',
    gap: '50px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  editGrayDiv: {
    backgroundColor: '#323232',
    padding: '15px',
    borderRadius: '11px',
    marginTop: '15px'
  },
  font14White400: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.94px',
    color: '#FFF2E3'
  },
  font14600: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
  },
  font14White400Line20: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20.58px',
    color: '#FFF2E3'
  },
  font14Red400Line20: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20.58px',
    color: '#FF7878'
  },
  titleDateWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleDateWrapNew: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  font36White: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "43.57px",
    color: "#FFF2E3",
    marginTop: '45px'
  },
  font36Red: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "43.57px",
    color: "#FF7878",
    marginTop: '45px'
  },
  font36Gray: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "43.57px",
    color: "#7D7D7D",
    marginTop: '45px'
  },
  font36Green: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "43.57px",
    color: "#61CA78",
    marginTop: '45px'
  },
  editTitleButtonWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  editTitleButtonWrapNew: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '20px 28px 0'
  },
  modalStepBox: {
    width: '94%',
    marginTop: '35px',
    maxHeight: '400px',
    height: '100%',
    overflow: 'auto',
    scrollbarWidth: 'none',
    backgroundColor: '#2F2F2F',
    padding: '20 28',
    borderRadius: '20px'
  },
  modalStepBoxNew: {
    marginTop: '35px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    backgroundColor: '#2F2F2F',
    borderRadius: '20px'
  },
  modalBottomButtonWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    marginTop: '25px'
  },
  allPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "flex-start",
  },
  savedMilestoneWrap:{
    margin: '50px 0',
    display: 'flex',
    justifyContent: 'space-between'
  }
}
// Customizable Area End